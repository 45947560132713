.container {
  padding: 0 2rem;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}


.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}


.sbutton{
  margin-left: 20px;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 2rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}
.patentLogo{

  height: 200px;
  animation: 6s ease 0s  appear;
}

@keyframes appear {
  0% {opacity: 0 ; scale : 0.5;}

  100% {opacity: 1;}
 
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  /* color: white; */
  text-decoration: none;
  border: 1px solid #0000;
  border-radius: 10px;
  background-color: rgb(0, 0, 0, 0.5);
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 600px;
  overflow: auto;
  text-align: center;
  animation: 1s ease 0s  appear;
}

.card:hover,
.card:focus,
.card:active {
  color: #348ef2;
  border-color: #348ef2;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.h2{
  justify-self: center;
}



@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
