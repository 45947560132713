@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: Titillium Web, Audiowide, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
   text-align: center;
}

p {

  font-family: Titillium Web, Audiowide, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color : white
}

h1, h2, h3, h4 {
  
color:white;
    font-family:  Audiowide, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  
}



a {
  color: white;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.card:hover,
.card:focus,
.card:active {
  color: #348ef2;
  border-color: #348ef2;
}

label{
  color:white;
}


.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  /* color: white; */
  text-decoration: none;
  border: 1px solid #0000;
  border-radius: 10px;
  background-color: rgb(0, 0, 0, 0.5);
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 500px;
  overflow: auto;
  text-align: center;
}

.card:hover,
.card:focus,
.card:active {
  color: #348ef2;
  border-color: #348ef2;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
  
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.h2{
  justify-self: center;
}



@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}